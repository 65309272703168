import { spline } from '@georgedoescode/spline';
import { createNoise2D } from 'simplex-noise';

const noise2D = createNoise2D();
const noiseStep = 0.001;
const blobPadding = 20;

function noise(x, y) {
    return noise2D(x, y);
}

function createPoints(maxLength) {
    const points = [];
    const numPoints = 6;
    const angleStep = (Math.PI * 2) / numPoints;
    const rad = maxLength / 2;

    for (let i = 1; i <= numPoints; i++) {
        const theta = i * angleStep;
        const x = blobPadding + (maxLength / 2) + Math.cos(theta) * rad;
        const y = blobPadding + (maxLength / 2) + Math.sin(theta) * rad;

        points.push({
            x: x,
            y: y,
            originX: x,
            originY: y,
            noiseOffsetX: Math.random() * (500 + (Math.random() * 500)),
            noiseOffsetY: Math.random() * (500 + (Math.random() * 500)),
        });
    }

    return points;
}

const blobs = [
    {
        pathSelector: '.blob_a path',
        points: createPoints(200)
    }, {
        pathSelector: '.blob_b path',
        points: createPoints(200)
    }, {
        pathSelector: '.blob_c path',
        points: createPoints(200)
    }, {
        pathSelector: '.blob_d path',
        points: createPoints(200)
    }, {
        pathSelector: '.blob_e path',
        points: createPoints(200)
    }, {
        pathSelector: '.blob_f path',
        points: createPoints(200)
    }
];

(function animate() {
    requestAnimationFrame(animate);
    blobs.forEach((blob, index) => {
        const blobPath = document.querySelector(blob.pathSelector);
        if (!blobPath) {
            blobs.splice(index, 1);
            return;
        }
        blobPath.setAttribute('d', spline(blob.points, 1, true));
        for (let i = 0; i < blob.points.length; i++) {
            const point = blob.points[i];
            const nX = noise(point.noiseOffsetX, point.noiseOffsetX);
            const nY = noise(point.noiseOffsetY, point.noiseOffsetY);
            const x = map(nX, -1, 1, point.originX - 20, point.originX + 20);
            const y = map(nY, -1, 1, point.originY - 20, point.originY - 20);
            point.x = x;
            point.y = y;
            point.noiseOffsetX += noiseStep;
            point.noiseOffsetY += noiseStep;
        }
    });
})();

function map(n, start1, end1, start2, end2) {
    return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
}

const handleScrollEvent = event => {
    navbarBottom = navbar.getBoundingClientRect().top;
    headerTop = header.getBoundingClientRect().top;
    if (window.scrollY > 70 && window.scrollY < 380) {
        navbar.classList.toggle('js-is-floating', true);
    } else {
        navbar.classList.toggle('js-is-floating', false);
    }
};

let navbarBottom = 0;
let headerTop = 0;
let navbar = null;
let header = null;

window.addEventListener('DOMContentLoaded', event => {

    navbar = document.querySelector('.js-navbar');
    if (!navbar) {
        return;
    }

    header = document.querySelector('.js-header');
    if (!header) {
        return;
    }

    const cs = window.getComputedStyle(navbar);

    document.addEventListener('scroll', handleScrollEvent);

    if (document.body.classList.contains('js-page-home')) {
        window.addEventListener('resize', handleWindowResizeForHome);
        handleWindowResizeForHome(null);
    } else if (document.body.classList.contains('js-page-internal')) {
        window.addEventListener('resize', handleWindowResizeForInternal);
        handleWindowResizeForInternal(null);
    }
});

const handleWindowResizeForHome = event => {
    if (window.innerWidth <= 1100) {
        const scale = window.innerWidth / 1160;
        header.style.transform = `scale(${scale}, ${scale})`;
        header.style.height = `${Math.round(270 * scale)}px`;
    } else {
        header.style.transform = `none`;
        header.style.height = null;
    }
}

const handleWindowResizeForInternal = event => {
    if (window.innerWidth < 1024) {
        const scale = window.innerWidth / 1000;
        header.style.transform = `scale(${scale}, ${scale})`;
        header.style.height = `${Math.round(270 * scale)}px`;
    } else {
        header.style.transform = `none`;
        header.style.height = null;
    }
}