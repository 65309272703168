const domContentLoadedHandler = event => {
    if(!document.querySelector('.js-page-certs')) {
        return;
    }

    const rootTextarea = document.querySelector('.js-root-text');
    const intTextarea = document.querySelector('.js-int-text');
    const rootCopyButton = document.querySelector('.js-copy-root-btn');
    const intCopyButton = document.querySelector('.js-copy-int-btn');

    rootCopyButton.addEventListener('click', event => {
        copyToClipboard(rootTextarea.value)
        .then(() => {
            
            clearTextTimeout(rootCopyButton);

            rootCopyButton.aql_copyTextTimeout = setTimeout(() => {
                rootCopyButton.textContent = 'Copy';
                clearTextTimeout(rootCopyButton);
            }, 1000);
            
            rootCopyButton.textContent = 'Copied!';
        })
        .catch(() => console.log('Copy text error'));
    });

    intCopyButton.addEventListener('click', event => {
        copyToClipboard(intTextarea.value)
        .then(() => {
            
            clearTextTimeout(intCopyButton);

            intCopyButton.aql_copyTextTimeout = setTimeout(() => {
                intCopyButton.textContent = 'Copy';
                clearTextTimeout(intCopyButton);
            }, 1000);
            
            intCopyButton.textContent = 'Copied!';
        })
        .catch(() => console.log('Copy text error'));
    });
};

const copyToClipboard = copyText => {
    if (navigator.clipboard && window.isSecureContext) {
        return navigator.clipboard.writeText(copyText);
    } else {
        let textarea = document.createElement('textarea');
        textarea.value = copyText;
        textarea.style.position = 'fixed';
        textarea.style.left = '-999999px';
        textarea.style.top = '-999999px';
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        return new Promise((res, rej) => {
            document.execCommand('copy') ? res() : rej();
            textarea.remove();
        });
    }
}

const clearTextTimeout = copyButton => {
    clearTimeout(copyButton.aql_copyTextTimeout);
    copyButton.aql_copyTextTimeout = null;
}

window.addEventListener('DOMContentLoaded', domContentLoadedHandler);