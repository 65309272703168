import {
    Tab,
    initTE,
} from "tw-elements";

let tabItems = document.querySelectorAll('.tab-item a');

const tabItemClickHandler = event => {
    const target = event.target.dataset.teTarget.slice(1);
    if (!target.length) {
        return;
    }
    window.location.hash = target;
};

const domContentLoadedHandler = event => {

    initTE({ Tab });

    tabItems.forEach(item => {
        item.addEventListener('click', tabItemClickHandler);
        if (window.location.hash === item.dataset.teTarget) {
            item.click();
        }
    });
};

window.addEventListener('DOMContentLoaded', domContentLoadedHandler);