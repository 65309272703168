let menuIcon = null;
let mobileNav = null;

window.addEventListener('DOMContentLoaded', event => {

    menuIcon = document.querySelector('.menu-icon');
    mobileNav = document.querySelector('.js-mobile-navbar');
    if (menuIcon && mobileNav) {
        menuIcon.addEventListener('click', menuIconClickHandler);
        window.addEventListener('resize', handleWindowResizeForNav);
    }
});

const handleWindowResizeForNav = event => {
    if (window.innerWidth >= 1024) {
        menuIcon.classList.toggle('open', false);
        mobileNav.classList.toggle('hidden', true);
    }
}

const menuIconClickHandler = event => {
    menuIcon.classList.toggle('open');
    if (mobileNav.classList.contains('hidden')) {
        mobileNav.classList.toggle('hidden', false);
    } else {
        mobileNav.classList.toggle('hidden', true);
    }
};