const domContentLoadedHandler = event => {
    const cookieNotice = document.querySelector('.js-cookie-notice');
    if(!cookieNotice) {
        return;
    }
    
    if(!localStorage.getItem('acceptedCookies')) {
        fadeIn(cookieNotice);
    }

    const cookieAcceptButton = document.querySelector('.js-cookie-accept');
    cookieAcceptButton.addEventListener('click', event => {
        localStorage.setItem('acceptedCookies', true);
        fadeOut(cookieNotice);
    });
};

window.addEventListener('DOMContentLoaded', domContentLoadedHandler);

const fadeIn = (el, display) => {
    el.style.opacity = 0;
    el.style.display = display || 'block';
    (function fade() {
        let val = parseFloat(el.style.opacity);
        if (!((val += .05) > 1)) {
            el.style.opacity = val;
            requestAnimationFrame(fade);
        }
    })();
}

const fadeOut = (el) => {
    el.style.opacity = 1;
    (function fade() {
        if ((el.style.opacity -= .1) < 0) {
            el.style.display = 'none';
        } else {
            requestAnimationFrame(fade);
        }
    })();
};