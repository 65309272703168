import 'tw-elements';

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

require('./global-nav');
require('./header');
if (document.body.classList.contains('js-page-solutions')) {
    require('./solutions');
}
require('./cookie-notice');
require('./certificates');

document.body.classList.remove('preload');
